import AppConfig from 'shared/config-public';

export default function sayHello() {
	const { version } = AppConfig;

	// Console code is adapted from PIXI's "sayHello()" routine
	if (window.chrome) {
		let args = [
			`\n%c %c %c === Vaya ${version} ===  %c  %c \n Made with love by Vaya https://vaya.to/  %c %c \u2665%c\u2665%c\u2665 \n\n`,
			'background: #FFFAFF; padding:5px 0;',
			'background: #FFFAFF; padding:5px 0;',
			'color: #fff; background: #B5479B; padding:5px 0;',
			'color: #fff; background: #FFFAFF; padding:5px 0;',
			'color: #fff; background: rgba(106,42,91,1); padding:5px 0;',
			'color: #fff; background: rgba(106,42,91,1); padding:5px 0;',
			'color: rgba(106,42,91,1); background: #fff; padding:5px 0;',
			'color: rgba(106,42,91,1); background: #fff; padding:5px 0;',
			'color: #FFFAFF; background: #fff; padding:5px 0;',
		];
		window.console.log.apply(console, args);
	} else if (window.console) {
		window.console.log(
			`=== Vaya ${version} ===  Made with love by Vaya https://vaya.to/`,
		);
	}
}
